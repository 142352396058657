module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"eon-studios","short_name":"eon","start_url":"/","background_color":"#222222","theme_color":"#EC1C24","display":"minimal-ui","icon":"src/images/eon-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"51acf833273775dcc526210f74753013"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React, { useState } from 'react';

interface Context {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

export const MobileMenuContext = React.createContext<Context>({
  isOpen: false,
  toggleIsOpen: () => null,
});

const Provider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MobileMenuContext.Provider
      value={{
        isOpen,
        toggleIsOpen: () => setIsOpen(!isOpen),
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};

export default ({ element }: any) => <Provider>{element}</Provider>;

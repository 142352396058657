/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Provider from './src/components/Provider';
import 'fontsource-jockey-one';
import 'typeface-lato';

export const wrapRootElement = Provider;
